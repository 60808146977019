  //Allow binding of play/pause of animations on their visibility
function PlayAnimationOnView(jQString, animVar) {
  if (animVar && animVar.play) {
    jQuery(jQString).bind('inview', function (event, visible) {
        // console.log('bound', jQString)
        if (visible && animVar._paused) { animVar.play(); jQuery(jQString).addClass('isPlaying'); }
        else if (!visible && !animVar._paused) { animVar.pause(); jQuery(jQString).removeClass('isPlaying'); }
    });
  }
  else console.log("cannot bind play tiggers on", jQString, "on variable", animVar);
}

(function($) {
  var search;
  var navTimeOuts = [];

  function AnimateMenuItem(i) {
    var item = $(this);
    navTimeOuts.push(setTimeout(function() {
      if ($('nav').hasClass('open'))
        item.addClass("animate");
    }, 250 + (i * 75)));
  }

  function StopPropagation(e) { e.stopPropagation(); };
  function OpenMenu() {
    $('nav').addClass('open');
    $('nav li').each(AnimateMenuItem);
  }
  function CloseMenu() {
    $('nav').removeClass('open');
    for (var i = 0; i < navTimeOuts.length; ++i) clearTimeout(navTimeOuts[i]);
    $('nav li').removeClass("animate");
  }
  function SearchFocusOn() { search.parent().parent().addClass('has-focus'); }
  function SearchFocusOff() { search.parent().parent().removeClass('has-focus'); }
  function SearchOnChange() {
    if ( search.val().length) search.parent().parent().addClass('has-input');
    else search.parent().parent().removeClass('has-input');
  }
  function CloseSearch() { 
    search.val('').blur().change();
    if (window.location.href.indexOf('search=') > -1 || window.location.href.indexOf('s=') > -1) {
      window.location.href = window.location.pathname;
    }
  }

  function createCookie(name,value,days) {
    if (days) {
      var date = new Date();
      date.setTime(date.getTime()+(days*24*60*60*1000));
      var expires = "; expires="+date.toGMTString();
    }
    else var expires = "";
    document.cookie = name+"="+value+expires+"; path=/";
  }

  function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
      var c = ca[i];
      while (c.charAt(0)==' ') c = c.substring(1,c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
  }

  function eraseCookie(name) {
    createCookie(name,"",-1);
  }

  $(document).ready(function() {
    search = $('#search');
    $('#menu-btn-text, #menu-btn, nav, nav .close-btn').click(OpenMenu);
    $('nav, nav .close-btn').click(CloseMenu);
    $('.nav-container').click(StopPropagation);
    search.on('click', function(e){
      $(this).select();
    });
    search.on('focus', SearchFocusOn);
    search.on('blur', SearchFocusOff);
    search.on('change keyup', SearchOnChange);
    $('#search_cancel').click(CloseSearch);

    if(!readCookie('cookieAccepted')) {
      $('body').addClass('cookie-not-accepted');
    } else {
      $('.cookie-message').remove();
    }

    $('.cookie-accept').click(function(e) {
      createCookie('cookieAccepted', 1, 365*20);
      $('.cookie-message').remove();
    });
  });

  window.GA_TRACK = function(act, lbl) {
    ga('send', 'event', PAGE_NAME, act, lbl);
  }

})(jQuery);